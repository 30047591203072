import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './contact.module.css'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <div className={styles.formWrapper}>
        <form
          name="contact"
          method="post"
          className={styles.form}
          netlify="true"
          netlify-honeypot="nope"
          action="/success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.formFieldWrapper}>
            <label
              htmlFor="name"
              className={styles.required}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
            />
          </div>

          <div className={styles.nope}>
            <label htmlFor="nope">
              Nope
            </label>
            <input
              type="text"
              id="nope"
              name="nope"
            />
          </div>

          <div className={styles.formFieldWrapper}>
            <label
              htmlFor="name"
              className={styles.required}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
            />
          </div>

          <div className={styles.formFieldWrapper}>
            <label
              htmlFor="subject"
              className={styles.required}
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              required
            />
          </div>

          <div className={styles.formFieldWrapper}>
            <label
              htmlFor="message"
              className={styles.required}
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              maxLength="500"
              rows="10"
              required
            />
          </div>

          <div className={styles.submitButtonWrapper}>
            <button
              type="submit"
              className={styles.submitButton}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </main>
    <Footer />
  </Layout>
)
